


















































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  }
})
export default class WorkingHoursComponent extends Vue {
  @Prop({default: []})
  private jobHourCategories!: string[];

  private working_hour_from = "";
  private working_hour_to = "";
  private checkedJobHourCategories = [];
  private otherButtonChecked = false;

  @Watch('working_hour_from')
  emitWorkingHourFromIfValid() {
    this.emitWorkingHourIfValid()
  }

  @Watch('working_hour_to')
  emitWorkingHourToIfValid() {
    this.emitWorkingHourIfValid()
  }

  @Watch('checkedJobHourCategories')
  emitCheckedJobHourCategories () {
    this.$emit("working_hours", this.checkedJobHourCategories)
  }

  private emitWorkingHourIfValid() {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>;
    observer.validate().then((isValid: boolean) => {
      const nullables = [0, "", null, undefined];
      const checkNotNull = (nullables.indexOf(this.working_hour_from) === -1 ) && (nullables.indexOf(this.working_hour_to) === -1);
      if (isValid && checkNotNull) {
        this.otherButtonChecked = true;
        this.$emit("working_hour_from", this.working_hour_from);
        this.$emit("working_hour_to", this.working_hour_to);
      } else {
        this.otherButtonChecked = false;
        this.$emit("working_hour_from", null);
        this.$emit("working_hour_to", null);
      }
    })
  }
}
