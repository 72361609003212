

















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ErrorModalComponent extends Vue {

  @Prop()
  private title!: string;
  
  @Prop()
  private text!: string;
  
  private hide() {
    this.$modal.show("error-modal");
  }

  protected mounted() {
    this.$modal.show("error-modal");
  }

}
