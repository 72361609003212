






































































import { Component, Vue, Watch } from 'vue-property-decorator';
import moment from "moment";

class Date {
  year: string;
  month: string;
  date: string;

  constructor(obj: moment.Moment) {
    this.year = obj.format("YYYY");
    this.month = obj.format("MM");
    this.date = obj.format("DD");
  }

  toString(): string {
    return `${this.year}-${this.month}-${this.date}`    
  }
}

@Component
export default class StartableDateComponent extends Vue {
  private datetime = new Date(moment());
  private selection = "";
  private _startableDate = "";
  private tomorrow = moment().add(1, 'days');
  private errors: string[] = []

  private get years(): number[] {
    const now = moment();
    const years: number[] = [];
    const startYear = now.year();
    for (let y = startYear; y<= now.year() + 5; y++) {
      years.push(y);
    }
    return years
  }

  private get months(): number[] {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  }

  private get dates(): number[] {
    const endOfMonth = moment(this.datetime.toString()).endOf('month');
    const dates: number[] = [];
    for (let d = 1; d <= endOfMonth.date(); d++) {
      dates.push(d);
    }
    return dates;
  }

  private get year(): number {
    return Number(this.datetime.year);
  }

  private set year(v: number) {
    this.datetime.year = v.toString();
    this.selection = "select"
    this.startableDate = this.datetime.toString();
    this.isValidDateTime()
  }

  private get month(): number {
    return Number(this.datetime.month);
  }

  private set month(v: number) {
    this.datetime.month = ("00" + v).slice(-2);
    this.selection = "select"
    this.startableDate = this.datetime.toString();
    this.isValidDateTime()
  }

  private get date(): number {
    return Number(this.datetime.date);
  }

  private set date(v: number) {
    this.datetime.date = ("00" + v).slice(-2);
    this.selection = "select"
    this.startableDate = this.datetime.toString();
    this.isValidDateTime()
  }

  private set startableDate(v: string) {
    this._startableDate = v;
    this.$emit("startable_date", v);
  }

  private get startableDate(): string {
    return this._startableDate;
  }

  @Watch('selection', { immediate: true, deep: true })
  switchStartableDate() {
    this.isValidDateTime()
    if (this.selection == 'tomorrow') {
      this.startableDate = this.tomorrow.format("YYYY-MM-DD");
    } else {
      this.startableDate = this.datetime.toString();
    }
  }

  private isValidDateTime(): boolean {
    this.errors = [];
    if (this.selection == 'select') {
      const dt = moment(this.datetime.toString())
      if (dt >= this.tomorrow) {
        return true
      }
      this.errors.push("仕事開始日は次の日以降の値を設定してください")
      return false
    }
    return true
  }
}

