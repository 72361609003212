













import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import JobFeature from '@/models/JobFeature';

@Component
export default class DemandConditionsComponent extends Vue {
  @Prop({default: () => ([])})
  private jobFeatures!: JobFeature[]

  @Prop({default: () => ([])})
  private queryParams!: string[]

  private checkedJobFeatures: string[] = [];
  private errors: string[] = [];

  protected mounted() {
    this.checkedJobFeatures = this.queryParams;
  }

  @Watch('checkedJobFeatures')
  emitCheckedJobFeatures() {
    this.$emit("job_features", this.checkedJobFeatures);
  }
}
