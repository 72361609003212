














































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

class Income {
  monthly: number|null;
  hourly: number|null;

  constructor(monthly_income: number|null, hourly_income: number|null) {
    this.monthly = monthly_income;
    this.hourly = hourly_income;
  }

  isValid(): boolean {
    if ((this.monthly) || (this.hourly)) {
      return true;
    }
    return false;
  }

}

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  }
})


export default class IncomeComponent extends Vue {
  private incomes = new Income(null, null);
  private monthlyIncomeChecked = false;
  private hourlyIncomeChecked = false;

  private get monthly_income_per_10k(): string {
    if (this.incomes.monthly == null) {
      return "";
    } else {
      return (this.incomes.monthly / 10000).toString();
    }
  }

  private set monthly_income_per_10k(v: string) {
    if (v == "") {
      this.incomes.monthly = null
      this.monthlyIncomeChecked = false;
    } else {
      this.incomes.monthly = parseInt(v) * 10000;
      this.monthlyIncomeChecked = true;
    }
  }

  private get hourly_income(): string {
    if (this.incomes.hourly == null) {
      return ""
    }
    return this.incomes.hourly.toString();
  }

  private set hourly_income(v: string) {
    if (v == "") {
      this.incomes.hourly = null;
      this.hourlyIncomeChecked = false;
    } else {
      this.incomes.hourly = parseInt(v);
      this.hourlyIncomeChecked = true;
    }
  }

  @Watch('incomes', {deep: true})
  updateIfValid() {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>;
    observer.validate().then((isValid: boolean) => {
      if (isValid) {
        this.$emit("monthly_income", this.incomes.monthly);
        this.$emit("hourly_income", this.incomes.hourly);
      } else {
        this.$emit("monthly_income", null);
        this.$emit("hourly_income", null);
      }
    })
  }
}
