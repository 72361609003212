import AreaGroup from '@/models/AreaGroup.ts';
import OccupationGroup from '@/models/OccupationGroup';
import JobFeature from '@/models/JobFeature';

export interface SearchFormParams {
    employment_statuses: string[];
    area_groups: AreaGroup[];
    occupation_groups: OccupationGroup[];
    job_features: JobFeature[];
}

export class SearchAPIParams {
    employment_statuses: string[];
    areas: string[];
    occupation_groups: string[];
    job_features: string[];
    keyword: string;

    constructor(employment_statuses: string[], areas: string[], occupation_groups: string[], job_features: string[], keyword: string) {
        this.employment_statuses = employment_statuses;
        this.areas = areas;
        this.occupation_groups = occupation_groups;
        this.job_features = job_features;
        this.keyword = keyword;
    }

    public isValid(): boolean {
        return this.employment_statuses.length > 0 || this.areas.length > 0 || this.occupation_groups.length > 0 || this.job_features.length > 0 || this.keyword.length > 0;
    }
}

export default class SearchFormRepository {
    private static _instance?: SearchFormRepository;
    public static get instance(): SearchFormRepository {
        if (!this._instance) {
            this._instance = new SearchFormRepository();
        }
        return this._instance;
    }

    private data: SearchFormParams = window.DATA;

    public getEmplomentStatuses(): string[] {
        if (this.data) {
            return this.data.employment_statuses;
        }
        return [];
    }

    public getAreaGroups(): AreaGroup[] {
        if (this.data) {
            return this.data.area_groups;
        }
        return [];
    }

    public getOccupationGroups(): OccupationGroup[] {
        if (this.data) {
            return this.data.occupation_groups;
        }
        return [];
    }

    public getJobFeatures(): JobFeature[] {
        if (this.data) {
            return this.data.job_features;
        }
        return [];
    }

    public executeSearch(params: SearchAPIParams) {
        const searchParams = this.convertToURLSearchParams(params)                
        window.location.href = `/jobs/search?${searchParams.toString()}`
    }

    protected convertToURLSearchParams(params: SearchAPIParams): URLSearchParams {
        const urlParams = new URLSearchParams();
        for (const [key, value] of Object.entries(params)) {
            if (typeof value === "string") {
                urlParams.append(key, value);
            } else {
                value.forEach((v: string) => {
                    urlParams.append(key, v);
                })
            }
        }
        return urlParams
    }
}
