
























import { Component, Vue, Watch, Prop } from 'vue-property-decorator';

@Component
export default class EmploymentStatusComponent extends Vue {
    @Prop({default: () => ([])})
    private employmentStatuses!: string[]

    @Prop({default: () => ([])})
    private queryParams!: string[]

    private pickedEmploymentStatuses: string[] = [];

    protected created() {
      if (this.queryParams) {
        this.pickedEmploymentStatuses = this.queryParams;
      } else {
        this.pickedEmploymentStatuses = [];
      }
    }

    @Watch('pickedEmploymentStatuses')
    emitPickedEmploymentStatuses() {
      this.$emit("employment_statuses", this.pickedEmploymentStatuses);
    }
}
