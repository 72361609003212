















import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import OccupationGroup from '@/models/OccupationGroup'

@Component
export default class OccupationComponent extends Vue {
  @Prop({default: () => ([])})
  private occupationGroups!: OccupationGroup[]

  @Prop({default: () => ([])})
  private queryParams!: string[];

  private checkedOccupationGroups: string[] = [];

  protected mounted() {
    this.checkedOccupationGroups = this.queryParams;
  }


  @Watch('checkedOccupationGroups')
  emitCheckedOccupatioinGroups() {
    this.$emit("occupation_groups", this.checkedOccupationGroups);
  }
}
