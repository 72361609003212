








































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { AxiosError } from 'axios';
import AddressComponent from "@/components/AddressComponent.vue";
import PhoneNumberComponent from "@/components/PhoneNumberComponent.vue"
import EmailComponent from "@/components/EmailComponent.vue";
import NameComponent from "@/components/NameComponent.vue";
import NameFuriganaComponent from "@/components/NameFuriganaComponent.vue";
import RequestComponent from "@/components/RequestComponent.vue";
import CompanyEntryFormRepository, { CompanyEntryFormParams } from '@/repositories/CompanyEntryFormRepository'
import { delay } from '@/utils'
import ErrorModalComponent from '@/components/ErrorModalComponent.vue';

@Component({
  components: {
    AddressComponent,
    PhoneNumberComponent,
    EmailComponent,
    NameComponent,
    NameFuriganaComponent,
    RequestComponent,
    ValidationProvider,
    ValidationObserver,
  }
})
export default class CompanyEntryForm extends Vue {
  private confirmed = false;
  private data = {
    company_name: "",
    surname: "",
    given_name: "",
    surname_hiragana: "",
    given_name_hiragana: "",
    phone_number: "",
    email: "",
    prefecture: "",
    postal_code: "",
    address: "",
    requests: [],
    job_content: "",
  }

  private get job_content(): string {
    return this.data.job_content
  }

  private set job_content(v: string) {
    this.data.job_content = v;
  }

  @Watch('job_content')
  validate() {
    const provider = this.$refs.jobContentProvider as InstanceType<typeof ValidationProvider>;
    delay(10).then(() => {
      provider.validate();
    });
  }

  private postCompanyEntryConfirm() {
    this.confirmed = true;
    window.scroll({top: 0, behavior: "auto"})
  }

  private postComapnyEntryCancel() {
    this.confirmed = false;
    window.scroll({top: 0, behavior: "auto"})
  }

  private showServerErrorModal() {
    this.$modal.show(ErrorModalComponent, {
      title: '一時的に利用できません',
      text: '大変申し訳ありませんが、再度時間をおいてアクセスしてください',
    });
  }

  private postCompanyEntry() {
    const instance = CompanyEntryFormRepository.instance;
    const csrfErrorCode = [401, 403]
    instance.postCompanyEntry(this.data as unknown as CompanyEntryFormParams, () => {
      window.location.href = "/company/entry/completed";
    }, (error: AxiosError) => {
      if (error.response) {
        if (csrfErrorCode.includes(error.response.status)) {
          window.location.href = "/company/entry/top";
        } else {
          this.showServerErrorModal();
        }
      }      
    });
  }  
}

