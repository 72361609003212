








declare global {
  interface Window {
    APP_COMPONENT: string;
  }
}

import { Component, Vue } from 'vue-property-decorator';
import VModal from 'vue-js-modal'
import UserEntryForm from "@/components/UserEntryForm.vue";
import SearchForm from "@/components/SearchForm.vue";
import CompanyEntryForm from "@/components/CompanyEntryForm.vue";
import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import libphonenumber from 'google-libphonenumber';
import moment from 'moment';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

Vue.use(VModal);

for (const [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation
  });
}

extend('postalCode', {
    message: (field) => { return field + "は数字のみ入力できます" },
    validate: (value) => { return /^[0-9]+$/.test(value)},
});

extend('jaChar', {
  message: (field) => { return field + "は全角ひらがな、全角カタカナ、漢字のみ使用できます" },
  validate: (value) => { return /^[ぁ-んァ-ン一-龥０-９Ａ-Ｚー−\s\S]+$/.test(value) }
});

extend('hiragana', {
  message: (field) => { return field + "は全角ひらがなのみ使用できます" },
  validate: (value) => {  return /^[ぁ-ん]+$/.test(value) }
})

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

extend('phoneNumber', {
  message: (field) => { return field + "の正しい形式ではありません" },
  validate: (value) => {
    try {
      const nullables = [0, "", null, undefined];
      if (nullables.indexOf(value) !== -1) {
        return false;
      }
      const phoneNumber = phoneUtil.parseAndKeepRawInput(value, 'JP');
      return phoneUtil.isValidNumber(phoneNumber);
    } catch (err) {
      return false;
    }
  },
});

extend('adult', {
  message: () => { return "18歳以上のみ登録できます"; },
  validate: (value) => {
    return moment().diff(moment(value), 'years') >= 18;
  }
})


Vue.filter('phoneNumber', function(value: string): string | null {
  if (!value) {
    return null;
  }
  const phoneUtil = PhoneNumberUtil.getInstance();
  const phoneNumber = phoneUtil.parseAndKeepRawInput(value, 'JP');
  return phoneUtil.format(phoneNumber, PhoneNumberFormat.NATIONAL)
});

Vue.filter('age', function(value: string): number | null {
  if (!value) {
    return null;
  }
  return moment().diff(moment(value), 'years')
});

Vue.filter('startableDate', function(value: string): string | null {
  if (!value) {
    return null
  }
  if (value == moment().add(1, 'days').format('YYYY-MM-DD')) {
    return '明日からでも可能'
  }
  return value;
});


@Component({
  components: {
    UserEntryForm,
    SearchForm,
    CompanyEntryForm,
  },
})
export default class App extends Vue {
  get component() {
    return window.APP_COMPONENT;
  }

  get data() {
    return window.DATA;
  }
}
