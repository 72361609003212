
































import { Component, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  }
})
export default class EmailPickerComponent extends Vue {
  private email = "";
  private email_confirmation = "";
  private _validEmail = "";

  @Watch('email')
  onEmailChanged() {
    this.setValidEmail();
  }

  @Watch('email_confirmation')
  onEmailConfirmationChanged() {
    this.setValidEmail();
  }
 
  private setValidEmail() {
    const observer = this.$refs.validationObserver as InstanceType<typeof ValidationObserver>
    observer.validate().then((isValid: boolean) => {
      if (isValid) {
        this.validEmail = this.email;
      } else {
        this.validEmail = "";
      }
    });

  }

  private get validEmail(): string {
    return this._validEmail
  }

  private set validEmail(v: string) {
    if (this._validEmail !== v) {
      this._validEmail = v;
      this.$emit("email", v);
    }
  }
}

