var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",attrs:{"tag":"div"}},[_c('div',{staticClass:"c-input"},[_c('div',{staticClass:"c-input__cols -zip"},[_c('div',{staticClass:"c-input__col -zipMark"},[_c('span',{staticClass:"c-input__unit"},[_vm._v("〒")])]),_c('div',{staticClass:"c-input__col -zip"},[_c('ValidationProvider',{ref:"formerProvider",attrs:{"name":"郵便番号前半","rules":"required|length:3|postalCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.postal_code_former),expression:"postal_code_former"}],staticClass:"c-input__text -zipBefore",attrs:{"type":"text","id":"postal_code_former","placeholder":"000"},domProps:{"value":(_vm.postal_code_former)},on:{"input":function($event){if($event.target.composing){ return; }_vm.postal_code_former=$event.target.value}}}),(errors)?_c('div',[_c('p',{staticClass:"c-input__help -error"},[_vm._v(_vm._s(errors[0]))])]):_vm._e()]}}])})],1),_c('div',{staticClass:"c-input__col -zipSeparator"},[_c('span',{staticClass:"c-input__unit -zipSeparator"},[_vm._v("-")])]),_c('div',{staticClass:"c-input__col -zip"},[_c('ValidationProvider',{ref:"latterProvider",attrs:{"name":"郵便番号後半","rules":"required|length:4|postalCode","vid":"latterPostalCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.postal_code_latter),expression:"postal_code_latter"}],staticClass:"c-input__text -zipAfter",attrs:{"type":"text","id":"postal_code_latter","placeholder":"0000"},domProps:{"value":(_vm.postal_code_latter)},on:{"input":function($event){if($event.target.composing){ return; }_vm.postal_code_latter=$event.target.value}}}),(errors != [])?_c('div',[_c('p',{staticClass:"c-input__help -error"},[_vm._v(_vm._s(errors[0]))])]):_vm._e()]}}])})],1),_c('div',{staticClass:"c-input__col -zipAction"},[_c('a',{staticClass:"c-input__btnZip",attrs:{"id":"js-zip-trigger"},on:{"click":function($event){return _vm.displayAddressIfValid()}}},[_vm._v(" 住所表示 ")]),_c('p',{staticClass:"c-input__help -error"})])]),_c('p',{staticClass:"c-input__help"},[_vm._v("※郵便番号を入力して「住所を表示」を押してください。"),_c('br',{staticClass:"u-d-n-pc"}),_vm._v("都道府県・市区町村名が自動で入力されます。")])]),_c('div',{staticClass:"c-input"},[_c('ValidationProvider',{attrs:{"name":"都道府県","rules":"required","vid":"prefecture"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.prefecture),expression:"prefecture"}],staticClass:"c-input__select js-input-select -is-empty",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.prefecture=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":"","selected":"","value":""}},[_vm._v("都道府県選択")]),_vm._l((_vm.prefectures),function(prefecture){return _c('option',{key:prefecture,domProps:{"value":prefecture}},[_vm._v(_vm._s(prefecture))])})],2),(errors != [])?_c('div',[_c('p',{staticClass:"c-input__help -error"},[_vm._v(_vm._s(errors[0]))])]):_vm._e()]}}])})],1),_c('div',{staticClass:"c-input"},[_c('ValidationProvider',{ref:"addressProvider",attrs:{"name":"市区町村以下","rules":"required|jaChar|max:255","vid":"address","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function( ref ){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address),expression:"address"}],staticClass:"c-input__text",attrs:{"type":"text","id":"address","placeholder":"市区町村以下を入力"},domProps:{"value":(_vm.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.address=$event.target.value}}}),_c('p',{staticClass:"c-input__help"},[_vm._v("※番地・建物名・部屋番号まで全角で入力してください。")]),(errors != [])?_c('div',[_c('p',{staticClass:"c-input__help -error"},[_vm._v(_vm._s(errors[0]))])]):_vm._e()]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }