







































import { Component, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  }
})
export default class NameFuriganaComponent extends Vue {
  private surname_hiragana: string|null = null;
  private given_name_hiragana: string|null = null;

  @Watch('surname_hiragana')
  @Watch('given_name_hiragana')
  emitNamesHiraganaIfValid() {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>;
    observer.validate().then((isValid: boolean) => {
      if (isValid) {
        this.$emit("surname_hiragana", this.surname_hiragana);
        this.$emit("given_name_hiragana", this.given_name_hiragana);
      } else {
        this.$emit("surname_hiragana", null);
        this.$emit("given_name_hiragana", null);
      }
    });
  }
}

