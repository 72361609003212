






























import { Component, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { delay } from '@/utils'

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  }
})
export default class RequestComponent extends Vue {
  protected requests = ["お仕事の依頼", "人材派遣のお見積り", "人材紹介のお見積り", "その他"];
  private checkedRequests: string[] = [];

  @Watch('checkedRequests', {deep: true})
  emit() {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>;
    delay(10).then(() => {
      observer.validate().then(() => {
        // just enable validation
        this.$emit("requests", this.checkedRequests);
      })
    })
  }
}

