



















import { Component, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { PhoneNumberUtil, PhoneNumber, PhoneNumberFormat }  from 'google-libphonenumber';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  }
})
export default class PhoneNumberComponent extends Vue {
  private phone_number = ""; 

  @Watch('phone_number')
  emitPhoneNumberIfValid() {
    const nullables = [0, "", null, undefined];
    if (nullables.indexOf(this.phone_number) === -1) {
      const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>;
      observer.validate().then((isValid: boolean) => {
        if (isValid) {
          const util: PhoneNumberUtil = PhoneNumberUtil.getInstance();
          const phoneNumber: PhoneNumber = util.parseAndKeepRawInput(this.phone_number, 'JP');
          this.$emit("phone_number", util.format(phoneNumber, PhoneNumberFormat.NATIONAL).replaceAll('-', '')); 
        }
      });
    }
  }
}
