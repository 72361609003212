

































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import EmploymentStatusComponent from "@/components/EmploymentStatusComponent.vue";
import DemandConditionsComponent from "@/components/DemandConditionsComponent.vue";
import OccupationComponent from "@/components/OccupationComponent.vue";
import AreaComponent from "@/components/AreaComponent.vue";
import SearchFormRepository, { SearchAPIParams } from "@/repositories/SearchFormRepository"
import AreaGroup from '@/models/AreaGroup';
import OccupationGroup from '@/models/OccupationGroup';
import JobFeature from '@/models/JobFeature';

@Component({
  components: {
    EmploymentStatusComponent,
    DemandConditionsComponent,
    OccupationComponent,
    AreaComponent,
    ValidationObserver,
    ValidationProvider,
  }
})
export default class SearchForm extends Vue {
  private repository = SearchFormRepository.instance;
  private data = {
    employment_statuses: [],
    areas: [],
    occupation_groups: [],
    job_features: [],
    keyword: "",
  };

  private queryParams!: SearchAPIParams;

  private employmentStatuses: string[] = [];
  private areaGroups: AreaGroup[] = [];
  private occupationGroups: OccupationGroup[] = [];
  private jobFeatures: JobFeature[] = [];
  private isValid = false;

  protected created() {
    this.employmentStatuses = this.repository.getEmplomentStatuses();
    this.areaGroups = this.repository.getAreaGroups();
    this.occupationGroups = this.repository.getOccupationGroups();
    this.jobFeatures = this.repository.getJobFeatures();
    this.queryParams = this.parseQueryParams();
    this.data.keyword = this.queryParams.keyword;
  }

  protected parseQueryParams(): SearchAPIParams {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const employment_statuses = urlParams.getAll('employment_statuses');
    const areas = urlParams.getAll('areas');
    const occupation_groups = urlParams.getAll('occupation_groups');
    const job_features = urlParams.getAll('job_features');
    let keyword = urlParams.get('keyword')
    if (!keyword) {
      keyword = "";
    }
    return new SearchAPIParams(
      employment_statuses, areas, occupation_groups, job_features, keyword
    )
  }

  @Watch('data', {deep: true})
  updateIsValid() {
    this.isValid = this.data.employment_statuses.length > 0 || this.data.areas.length > 0 || this.data.occupation_groups.length > 0 || this.data.job_features.length > 0 || this.data.keyword.length > 0;      
  }

  private submitSearch() {
    const params = new SearchAPIParams(
    this.data.employment_statuses,
    this.data.areas,
    this.data.occupation_groups,
    this.data.job_features,
    this.data.keyword
    );
    if (!params.isValid()) {
      const error: Record<string, string> = {
        'keyword': 'どれか一つの項目は選択してください'
      }
      const observer = this.$refs.rootObserver as InstanceType<typeof ValidationObserver>;
      observer.setErrors(error);
    } else {
      this.repository.executeSearch(params)
    }
  }
}
