import axios, { AxiosError } from 'axios';
import { get as getCookie } from 'es-cookie';

export interface CompanyEntryFormParams {
    company_name: string;
    surname: string;
    given_name: string;
    surname_hiragana: string;
    given_name_hiragana: string;
    phone_number: string;
    email: string;
    prefecture: string;
    postal_code: string;
    address: string;
    request: string[];
    job_content: string;
}

export default class CompanyEntryFormRepository {
    private static _instance?: CompanyEntryFormRepository;
    public static get instance(): CompanyEntryFormRepository {
        if (!this._instance) {
            this._instance = new CompanyEntryFormRepository()
        }
        return this._instance
    }

    postCompanyEntry(params: CompanyEntryFormParams,
                     completionHandler: () => void,
                     errorHandler: (data: AxiosError) => void) {
        const X_CSRF_TOKEN = getCookie('csrf_access_token');
        const config = {
            headers: {
                'X-CSRF-TOKEN': X_CSRF_TOKEN
            }
        }
        axios.post('/api/company/entry', params, config)
            .then(() => {
                completionHandler();
            })
            .catch((error: AxiosError) => {
                errorHandler(error);
            });
    }
}
