







































import { Component, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  }
})
export default class NameComponent extends Vue {
  private surname: string|null = null;
  private given_name: string|null = null;

  @Watch('surname')
  @Watch('given_name')
  emitNameIfValid() {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>;
    observer.validate().then((isValid: boolean) => {
      if (isValid) {
        this.$emit('surname', this.surname);
        this.$emit('given_name', this.given_name);
      } else {
        this.$emit('surname', null);
        this.$emit('given_name', null);
      }
    });
  }
}

