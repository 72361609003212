






























































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import AreaGroup from '@/models/AreaGroup';
import OccupationGroup from '@/models/OccupationGroup';
import JobFeature from '@/models/JobFeature';
import BirthdayPickerComponent from "@/components/BirthdayPickerComponent.vue";
import EmailComponent from "@/components/EmailComponent.vue";
import AddressComponent from "@/components/AddressComponent.vue";
import AreaComponent from "@/components/AreaComponent.vue";
import OccupationComponent from "@/components/OccupationComponent.vue";
import StartableDateComponent from "@/components/StartableDateComponent.vue";
import IncomeComponent from "@/components/IncomeComponent.vue";
import WorkingHoursComponent from "@/components/WorkingHoursComponent.vue";
import DemandConditionsComponent from "@/components/DemandConditionsComponent.vue";
import EmploymentStatusComponent from "@/components/EmploymentStatusComponent.vue";
import NameComponent from "@/components/NameComponent.vue"
import NameFuriganaComponent from "@/components/NameFuriganaComponent.vue"
import PhoneNumberComponent from "@/components/PhoneNumberComponent.vue"
import UserFlowMediumComponent from "@/components/UserFlowMediumComponent.vue"
import UserEntryFormRepository, { UserEntryErrorIF } from "@/repositories/UserEntryFormRepository"
import User from '@/models/User';
import Questionnaire from '@/models/Questionnaire';
import ErrorModalComponent from '@/components/ErrorModalComponent.vue';

@Component({
  components: {
    BirthdayPickerComponent,
    EmailComponent,
    AddressComponent,
    AreaComponent,
    OccupationComponent,
    StartableDateComponent,
    IncomeComponent,
    WorkingHoursComponent,
    DemandConditionsComponent,
    EmploymentStatusComponent,
    NameComponent,
    NameFuriganaComponent,
    PhoneNumberComponent,
    UserFlowMediumComponent,
    ValidationObserver,
  },
})
export default class UserEntryForm extends Vue {

  private confirmed = false;
  private repository = UserEntryFormRepository.instance;
  private data = {
    user: {
      surname: "",
      given_name: "",
      surname_hiragana: "",
      given_name_hiragana: "",
      birthday: "",
      postal_code: "",
      prefecture: "",
      address: "",
      phone_number: "",
      email: "",
      job_offer_id: this.repository.getJobOfferID(),
      demand: {
          startable_date: "",
          hourly_income: null,
          monthly_income: null,
          working_hour_from: null,
          working_hour_to: null,
          //
          employment_statuses: [],
          working_hours: [],
          areas: [],
          occupation_groups: [],
          job_features: [],
      },
    },
    questionnaire: {
      media: [],
      other_medium: "",
      questionnaire_other: ""
    },
  }
  private employmentStatuses: string[] = [];
  private areaGroups: AreaGroup[] = [];
  private occupationGroups: OccupationGroup[] = [];
  private jobFeatures: JobFeature[] = [];
  private jobHourCategories: string[] = [];

  protected created() {
    this.employmentStatuses = this.repository.getEmploymentStatuses();
    this.areaGroups = this.repository.getAreaGroups();
    this.occupationGroups = this.repository.getOccupationGroups();
    this.jobFeatures = this.repository.getJobFeatures();
    this.jobHourCategories = this.repository.getJobHourCategories();
  }

  protected createParams() {
    const user = this.data.user as unknown as User
    const questionnaire = this.data.questionnaire as Questionnaire
    return {
      'user': user,
      'questionnaire': questionnaire,
    }
  }

  protected messageMapper: {[k: string]: {[k: string]: string}} = {
    email: {
      vid: "email",
      field: "E-Mail",
    },
    birthday: {
      vid: "birthday",
      field: "誕生日",
    },
    postal_code: {
      vid: "latterPostalCode",
      field: "郵便番号",
    },
    prefecture: {
      vid: "prefecture",
      field: "都道府県",
    },
    address: {
      vid: "address",
      field: "市区町村以下"
    },
    surname: {
      vid: "surname",
      field: "姓",
    },
    given_name: {
      vid: "given_name",
      field: "名",
    },
    surname_hiragana: {
      vid: "surname_hiragana",
      field: "せい",
    },
    given_name_hiragana: {
      vid: "given_name_hiragana",
      field: "めい",
    },
    phone_number: {
      vid: "phone_number",
      field: "電話番号"
    },
    hourly_income: {
      vid: 'hourly_income',
      field: "時給",
    },
    monthly_income: {
      vid: 'monthly_income',
      field: "月収",
    }
  }


  private parseErrors(errors: UserEntryErrorIF[]) {
    const reducer = (accumulator: {[k: string]: string[]}, currentValue: UserEntryErrorIF) => {
      const loc = currentValue.loc[0];
      const type = currentValue.type;
      const category = currentValue.category;
      const field = this.messageMapper[loc].field;
      const vid = this.messageMapper[loc].vid;
      let message = "";

      if (category == 'db') {
        const reason = type.split('.')[1];
        switch (reason) {
        case 'duplicated':
          message = `${field}の値はすでに登録されています`;
        }
      } else if (category == 'validation') {
        message = `${field}の値を見直してください`;
      }
      //
      if (accumulator[vid]) {
        accumulator[vid].push(message)
      } else {
        accumulator[vid] = [message]
      }
      return accumulator
    }

    const errorMessages = errors.reduce(reducer, {})

    const observer = this.$refs.rootObserver as InstanceType<typeof ValidationObserver>;
    observer.setErrors(errorMessages);
  }

  private showServerErrorModal() {
    this.$modal.show(ErrorModalComponent, {
      title: '一時的に利用できません',
      text: '大変申し訳ありませんが、再度時間をおいてアクセスしてください',
    });
  }

  private confirmation() {
    const params = this.createParams();
    const csrfErrorCode = [401, 403]
    this.repository.postConfirmation(params, () => {
      this.confirmed = true
      window.scroll({top: 0, behavior: "auto"})
    }, (error) => {
      if (error.response) {
        if (csrfErrorCode.includes(error.response.status)) {
          // redirect entry top view to generate cookies
          window.location.href = "/user/entry/top";
        } else if (error.response.status >= 500) {
          this.showServerErrorModal();
        } else {
          this.parseErrors(error.response.data)
          window.scroll({top: 0, behavior: "auto"})
        }
      }
    })
  }

  private revert() {
    this.confirmed = false;
  }

  private submitUserEntry() {
    const params = this.createParams()
    const csrfErrorCode = [401, 403]
    this.repository.postCreate(params, () => {
      window.location.href = "/user/entry/completed";
    }, (error) => {
      if (error.response) {
        if (csrfErrorCode.includes(error.response.status)) {
          // redirect entry top view to generate cookies
          window.location.href = "/user/entry/top";
        } else if (error.response.status >= 500) {
          this.showServerErrorModal();
        }
        else {
          this.parseErrors(error.response.data)
          window.scroll({top: 0, behavior: "auto"})
        }
      }
    })
  }
}
