import axios, { AxiosError } from 'axios';
import { get as getCookie } from 'es-cookie';
import AreaGroup from '@/models/AreaGroup.ts';
import OccupationGroup from '@/models/OccupationGroup';
import JobFeature from '@/models/JobFeature';
import User from '@/models/User';
import Questionnaire from '@/models/Questionnaire';

declare global {
    interface Window {
        DATA: UserEntryFormParams;
    }
}

export interface UserEntryFormParams {
    employment_statuses: string[];
    area_groups: AreaGroup[];
    occupation_groups: OccupationGroup[];
    job_features: JobFeature[];
    job_hour_categories: string[];
    job_offer_id: number | null;
}

export interface UserEntrySuccessIF {
    result: boolean;
}

export interface UserEntryErrorIF {
    loc: string[];
    msg: string;
    type: string;
    category: string;
}

export default class UserEntryFormRepository {
    private static _instance?: UserEntryFormRepository;
    public static get instance(): UserEntryFormRepository {
        if (!this._instance) {
            this._instance = new UserEntryFormRepository();
        }
        return this._instance
    }

    private data: UserEntryFormParams = window.DATA;

    public getEmploymentStatuses(): string[] {
        if (this.data) {
            return this.data.employment_statuses;
        }
        return [];
    }

    public getAreaGroups(): AreaGroup[] {
        if (this.data) {
            return this.data.area_groups;
        }
        return [];
    }

    public getOccupationGroups(): OccupationGroup[] {
        if (this.data) {
            return this.data.occupation_groups;
        }
        return [];
    }

    public getJobFeatures(): JobFeature[] {
        if (this.data) {
            return this.data.job_features;
        }
        return [];
    }

    public getJobHourCategories(): string[] {
        if (this.data) {
            return this.data.job_hour_categories;
        }
        return [];
    }

    public getJobOfferID(): number|null {
        if (this.data) {
            return this.data.job_offer_id;
        }
        return null;
    }

    public postConfirmation(params: {[key: string]: User|Questionnaire},
                            completionHandler: () => void,
                            errorHandler: (data: AxiosError<UserEntryErrorIF[]>) => void) {
        const X_CSRF_TOKEN = getCookie('csrf_access_token');
        const config = {
            headers: {
                'X-CSRF-TOKEN': X_CSRF_TOKEN
            }
        }
        axios.post<UserEntrySuccessIF>('/api/user/entry/confirm', params, config)
            .then(() => {
                completionHandler();
            })
            .catch((error: AxiosError<UserEntryErrorIF[]>) => {
                errorHandler(error)
            })
    }
    public postCreate(params: {[key: string]: User|Questionnaire},
                            completionHandler: () => void,
                            errorHandler: (data: AxiosError<UserEntryErrorIF[]>) => void) {
        const X_CSRF_TOKEN = getCookie('csrf_access_token');
        const config = {
            headers: {
                'X-CSRF-TOKEN': X_CSRF_TOKEN
            }
        }
        axios.post<UserEntrySuccessIF>('/api/user/entry', params, config)
            .then(() => {
                completionHandler();
            })
            .catch((error: AxiosError<UserEntryErrorIF[]>) => {
                errorHandler(error);
            })
    }
}  
