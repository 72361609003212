import Vue from 'vue';
import App from "@/App.vue";
import { ValidationProvider, localize } from 'vee-validate';
import ja from 'vee-validate/dist/locale/ja.json';


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')

Vue.component('ValidationProvider', ValidationProvider);
localize('ja', ja);
