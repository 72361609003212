
























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { PREFECTURES } from '@/models/Prefecture';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ValidationResult } from 'vee-validate/dist/types/types';
import { delay } from '@/utils';
import axios from 'axios';


interface PostalCode {
  former: string;
  latter: string;
}

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  }
})
export default class AddressComponent extends Vue {
  private prefecture = "";
  private address = "";
  private prefectures = PREFECTURES;

  private postalCode: PostalCode = {'former': "", 'latter': ""};

  private get postal_code_former(): string {
    if (this.postalCode) {
      return this.postalCode.former;
    }
    return "";
  }

  private set postal_code_former(v: string) {
    this.postalCode.former = v;
  }

  private get postal_code_latter(): string {
    if (this.postalCode) {
      return this.postalCode.latter;
    }
    return "";
  }

  private set postal_code_latter(v: string) {
    this.postalCode.latter = v
  }

  private get postal_code(): string {
    if (this.postalCode) {
      return this.postalCode.former + this.postalCode.latter;
    }
    return "";
  }

  @Watch('postal_code', {deep: true})
  updateFromPostalCode () {
    if (this.postal_code.length === 7) {
      this.$emit("postal_code", this.postal_code);
    }
  }

  @Watch('prefecture', {immediate: true})
  emitPrefectureIfValid() {
    if (this.prefecture != "") {
        this.$emit("prefecture", this.prefecture);
    } else {
        this.$emit("prefecture", null);
    }
  }

  @Watch('address', {immediate: true})
  emitAddressIfValid() {
    delay(1).then(() => {
      const provider = this.$refs.addressProvider as InstanceType<typeof ValidationProvider>;
      if (provider) {
        provider.validate().then((result) => {
          if (result.valid) {
            this.$emit("address", this.address);
          } else {
            this.$emit("address", null);
          }
        })
      }
    });
  }

  private displayAddressIfValid() {
    delay(1).then(() => {
      const former = this.$refs.formerProvider as InstanceType<typeof ValidationProvider>;
      const latter = this.$refs.latterProvider as InstanceType<typeof ValidationProvider>;
      former.validate().then((result: ValidationResult) => {
        if (result.valid) {
          latter.validate().then((result: ValidationResult) => {
            if (result.valid) {
              axios.get(`https://api.zipaddress.net/?zipcode=${this.postal_code}`)
                .then((response) => {
                  if (response.data.data) {
                    this.prefecture = response.data.data.pref;
                    this.address = response.data.data.address;
                  } else {
                    alert("入力ができませんでした");
                  }
                });
            }
          });
        }
      });
    });
  }
}

