export const USER_FLOW_MEDIA = [
    "雑誌",
    "求人情報誌",
    "折り込み・チラシ",
    "インターネット",
    "携帯ホームページ",
    "キーワード広告",
    "ラジオ・テレビ",
    "友人・知人",
]
