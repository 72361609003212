















































import { USER_FLOW_MEDIA } from '@/models/UserFlowMedium'
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';

extend('eitherRequired', {
  validate: (value): boolean => { 
    return value.isValid()
  },
  message: '一つはチェックするか、その他をご記入ください'
})

class UserFlowMedia {
  media: string[];
  other: string|null;

  constructor(media: string[], other: string|null) {
    this.media = media;
    this.other = other;
  }

  isValid(): boolean {
    if ((this.media.length > 0) || ([null, undefined, ''].indexOf(this.other) === -1)) {
      return true;
    }
    return false;
  }
}

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  }
})
export default class UserFlowMediumComponent extends Vue {
  private media: string[] = USER_FLOW_MEDIA;
  private checkedUserFlowMedia: string[] = [];
  private answer = new UserFlowMedia([], null);
  private otherUserFlowMediumChecked = false;

  @Watch('checkedUserFlowMedia')
  updateKlassAndEmit() {
    this.answer.media = this.checkedUserFlowMedia;
    this.emitIfValid();
  }

  private get otherUserFlow(): string|null {
    return this.answer.other
  }

  private set otherUserFlow(v: string|null) {
    this.answer.other = v;
    this.otherUserFlowMediumChecked = [null, undefined, ''].indexOf(v) === -1
    this.emitIfValid();
  }

  private emitIfValid() {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>;
    observer.validate().then((isValid: boolean) => {
      if (isValid) {
        this.$emit("media", this.answer.media);
        this.$emit("other_medium", this.answer.other);
      } else {
        this.$emit("media", null);
        this.$emit("other_medium", null);
      }
    })
  }
}

