














































import { Component, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { delay } from '@/utils'
import moment from "moment";


@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  }
})
export default class BirthdayPickerComponent extends Vue {
  private datetime: string = moment().format('YYYY-MM-DD')

  private get years(): number[] {
    const now = moment();
    const years: number[] = [];
    const startYear = moment().add(-100, 'year').year()
    for (let y = startYear; y <= now.year(); y++) {
      years.push(y);
    }
    return years;
  }

  private get months(): number[] {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  }

  private get dates(): number[] {
    const endOfMonth = moment(this.datetime).endOf('month');
    const dates: number[] = [];
    for (let d = 1; d <= endOfMonth.date(); d++) {
      dates.push(d);
    }
    return dates;
  }

  private get year(): number {
    return moment(this.datetime).year();
  }

  private set year(v: number) {
    this.datetime = moment(this.datetime).set('year', v).format('YYYY-MM-DD');
    this.validateAndEmitBirthday();
  }

  private get month(): number {
    return moment(this.datetime).month() + 1;
  }

  private set month(v: number) {
    this.datetime = moment(this.datetime).set('month', v - 1).format('YYYY-MM-DD');
    this.validateAndEmitBirthday();
  }

  private set date(v: number) {
    this.datetime = moment(this.datetime).set('date', v).format('YYYY-MM-DD');
    this.validateAndEmitBirthday();
  }

  private get date(): number {
    return moment(this.datetime).date();
  }

  @Watch('datetime', {deep: true})
  private validateAndEmitBirthday() {
    delay(1).then(() => {
      const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>;
      observer.validate().then((isValid) => {
        if (isValid) {
          this.$emit("birthday", this.datetime);
        }
      })
    });
  }
}
