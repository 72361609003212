
















































import { Component, Vue, Prop } from 'vue-property-decorator';
import AreaGroup from '@/models/AreaGroup';
import Area from '@/models/Area';

@Component
export default class AreaComponent extends Vue {

  @Prop({default: () => ([])})
  private areaGroups!: AreaGroup[]

  @Prop({default: () => ([])})
  private queryParams!: string[];

  private checkedAreas: string[] = [];
  private checkedAreaGroups: AreaGroup[] = [];
  private isAllChecked = false;

  protected mounted() {
    this.checkedAreas = this.queryParams;
  }

  private get allAreaNames(): string[] {
    const areaNames: string[] = [];
    this.areaGroups.forEach((g: AreaGroup) => {
      g.areas.forEach((a: Area) => {
        areaNames.push(a.name);
      });
    });
    return areaNames
  }

  private checkAll() {
    if (this.isAllChecked) {
      this.checkedAreas = this.allAreaNames;
      this.checkedAreaGroups = this.areaGroups;
    } else {
      this.checkedAreas = [];
      this.checkedAreaGroups = [];
    }
    this.$emit("areas", this.checkedAreas);
  }

  private checkAllShouldBeChecked() {
    if (this.checkedAreas.length == this.allAreaNames.length) {
      this.isAllChecked = true;
    } else {
      this.isAllChecked = false;
    }
  }

  private checkGroupShouldBeChecked() {
    const areaGroupShouldBeChecked: AreaGroup[] = [];
    this.areaGroups.forEach((g: AreaGroup) => {
        const isChecked = this.doAreasUnderGroupContainCheckedAreas(g.areas)
        if (isChecked) {
          areaGroupShouldBeChecked.push(g);
        }
    });
    this.checkedAreaGroups = areaGroupShouldBeChecked;
  }

  private notifyFromAreas() {
    this.checkAllShouldBeChecked();
    this.checkGroupShouldBeChecked();
    this.$emit("areas", this.checkedAreas);
  }

  private notifyFromAreaGroups() {
    const areasShouldBeChecked: string[] = [];
    this.checkedAreaGroups.forEach((g: AreaGroup) => {
      g.areas.forEach((a: Area) => {
        areasShouldBeChecked.push(a.name);
      });
    });

    // 1. if group is checked, areas under group always should be checked
    // 2. if group is not checked and areas under group are all checked,
    //    the areas should not be checked.
    // 3. if group is not checked and areas under group are not all checked,
    //    the areas should be checked
    const uncheckedGroups = this.areaGroups.filter(item => this.checkedAreaGroups.indexOf(item) < 0);
    let areasShouldBeAdded: string[] = [];
    for (const g of uncheckedGroups) {
      const cur: string[] = [];
      for (const area of g.areas) {
        if (this.checkedAreas.includes(area.name)) {
          cur.push(area.name);
        }
      }
      if (cur.length !== g.areas.length) {
        areasShouldBeAdded = areasShouldBeAdded.concat(cur);
      }
    }
    this.checkedAreas = areasShouldBeChecked.concat(areasShouldBeAdded);
    this.checkAllShouldBeChecked();
    this.$emit("areas", this.checkedAreas);
  }

  private doAreasUnderGroupContainCheckedAreas(areas: Area[]): boolean {
    for (const area of areas) {
      if (!this.checkedAreas.includes(area.name)) {
        return false;
      }
    }
    return true
  }
}
